<template>
  <div class="box">
    <el-dialog
      title="未知故障"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="main">
        <img
          src="@/assets/images/abnormalAlarm.png"
          style="width: 80px; height: 80px"
          class="main-bg"
        />

        <div class="content">
          <div class="content-item">
            <div class="content-title">
              厂站名称:
            </div>
            <div>{{ formData.powerStationName }}</div>
          </div>
          <div class="content-item">
            <div class="content-title">设备名称:</div>
            <div>{{ formData.deviceName }}</div>
          </div>
          <div class="content-item">
            <div class="content-title">告警类型:</div>
            <div>{{ formData.signalTypeName }}</div>
          </div>
          <div class="content-item">
            <div class="content-title">
              级别:
            </div>
            <div>
              {{
                formData.level == 0
                  ? "正常状态"
                  : formData.level == 1
                  ? "紧急"
                  : ""
              }}
            </div>
          </div>
          <div class="content-item">
            <div class="content-title">产生时间:</div>
            <div>{{formData.createtime}}</div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      formData: {}
    };
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    init() {
      this.getData();
    },
    async getData() {
      let res = await this.$http.post("/deviceAlarmRecord/queryLastOne", {});
      if (res.data.code === 200) {
        if(res.data.data.level != 0){
          this.centerDialogVisible = true;
        }
        this.formData = res.data.data;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.box {
  background-color: #0c4c7b;
  .main {
    display: flex;
    margin-top: 20px;
    .main-bg {
      margin-top: 10px;
      margin-right: 80px;
    }
    .content {
      .content-item {
        display: flex;
        color: #fff;
        .content-title {
          width: 80px;
          font-size: 14px;
          text-align: justify;
        }
      }
    }
  }
  /deep/ .el-dialog__header {
    background-color: #d7011c;
    color: #fff;
  }
  /deep/ .el-dialog__title {
    color: #fff;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
  /deep/ .el-dialog--center .el-dialog__body {
    background-color: #0c4c7b;
  }
  /deep/ .el-dialog--center .el-dialog__footer {
    background-color: #0c4c7b;
  }
}
</style>
