// 底部左侧数据
export const alartInformationOptions = {
  tooltip: {
    trigger: "item",
    formatter: "{b} : {c}台)",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      data: ["P(kw)", "q(kwa)", "cos", "F(hz)", "Fri", "Sat"],
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      axisLine: {
        lineStyle: {
          color: "#d7d7d7",
          width: 1,
        },
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      splitLine: {
        lineStyle: {
          type: "dashed", // 线型为虚线
          color: "#97b3cb",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#d7d7d7",
          width: 1,
        },
      },
    },
  ],
  series: [
    {
      name: "Direct",
      type: "bar",
      barWidth: "25%",
      data: [370, 700, 200, 400, 300, 300],
      itemStyle: {
        color: "#d7d7d7",
      },
      label: {
        show: true, // 开启标签显示
        position: "top", // 标签的位置，可以根据需要调整
        textStyle: {
          color: "#333333",
        },
      },
    },
  ],
};