<template>
  <div>
    <el-card>
      <el-form :inline="true">
        <el-form-item label="岗位名称">
          <el-input clearable v-model="queryInfo.condition.postName" style="width:200px"></el-input>
        </el-form-item>
        <el-button :loading="tableLoading" type="primary" @click="getListData">搜索</el-button>
        <el-button icon="el-icon-plus" type="primary" @click="add(true)">新增</el-button>
      </el-form>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        height="calc(100vh - 315px)"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          align="center">
        </el-table-column>
        <el-table-column
          prop="postName"
          align="center"
          width="130px"
          label="岗位名称">
        </el-table-column>
            <el-table-column
          prop="description"
          align="center"
          label="岗位描述">
        </el-table-column>
        
        <el-table-column
          align="center"
          width="160px"
          prop="createTime"
          label="创建时间">
          <template slot-scope="{row}">
            <span>{{ row.createTime | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column width="160px" label="操作" align="center">
          <template slot-scope="{row}">
            <el-button @click="add(false,row)" style="padding:0 4px" type="text">编辑</el-button>
          
            <el-popconfirm
            @onConfirm="del(row.id)"
              :title="`确定删除${row.postName}吗`"
            >
              <el-button slot="reference" style="color:red;margin-left:0;padding:0 4px" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.currPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <el-dialog
      :title="isAdd?'新增' :'编辑'"
      :visible.sync="dialogVisible"
      width="520px">
      <el-form label-width="90px">
        <el-form-item label="岗位名称" required>
        <el-input v-model="form.postName"  placeholder="请输入岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="岗位描述">
          <el-input v-model="form.description" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props:{
      deptCode:{
        type:String | Number,
        default:''
      }
    },
    watch:{
      deptCode(val){
        this.queryInfo.condition.deptCode = val
        this.form.deptCode = val
         this.getListData()
      }
    },
    data(){
      return{
        tableLoading:false,
        form:{
          deptCode:"",
          id:"",
          postName:"",
          description:"",
          groupCode:JSON.parse(localStorage.getItem('userInfo')).groupCode,
          groupName:JSON.parse(localStorage.getItem('userInfo')).groupName
        },
        dialogVisible:false,
        isAdd:false,
        total:0,
        queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition:{
          deptCode:"",
          postName:"",
          groupCode:JSON.parse(localStorage.getItem('userInfo')).groupCode
        }
      },
        tableData:[]
      }
    },
    created(){
      // this.getListData()
    },
    methods:{
     async del(id){
      let res=await this.$http.post('post/delete',{id})
      if(res.data.code==200){
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        this.getListData()
      }else{
        this.$message.error(res.data.message)
      }
      },
      add(flag,obj){ //true 新增 false 编辑
        this.isAdd=flag
        if(!flag){
          this.form.postName=obj.postName
          this.form.id=obj.id
          this.form.description=obj.description
        }
        this.dialogVisible=true
      },
    async  submit(){
      let data=JSON.parse(JSON.stringify(this.form))
      if(this.isAdd){
        delete data.id
      }
    let res=await this.$http.post('post/save',data)
    if(res.data.code==200){
      this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success'
        });
        this.dialogVisible=false
        this.getListData()
        this.form.postName=''
    }else{
      this.$message.error(res.data.message)
    }
      },
    async  getListData(){
      this.tableLoading=true
      let res=await this.$http.post('post/list',this.queryInfo)
      this.tableLoading=false
      if(res.data.code==200){
        this.tableData=res.data.data.data
        this.total=res.data.data.count
      }else{
        this.$message.error(res.data.message)
      }
      },
          // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },
    }
  }
</script>

<style lang="scss" scoped>

</style>