import * as echarts from "echarts";
// export const alartTopCenterOptions = (datas) => {
//   let options = {
//     tooltip: {
//       trigger: "item",
//       formatter: "{b} : {c}台)",
//     },
//     grid: {
//       left: "3%",
//       right: "4%",
//       bottom: "3%",
//       containLabel: true,
//     },
//     xAxis: [
//       {
//         type: "category",
//         data: datas.x,
//         axisTick: {
//           alignWithLabel: true,
//         },
//         axisLabel: {
//           show: true,
//           textStyle: {
//             color: "#97b3cb", // x轴颜色
//           },
//         },
//         axisLine: {
//           lineStyle: {
//             color: "#fff",
//             width: 1,
//           },
//         },
//       },
//     ],
//     yAxis: [
//       {
//         type: "value",
//         axisLabel: {
//           show: true,
//           textStyle: {
//             color: "#97b3cb", // x轴颜色
//           },
//         },
//         splitLine: {
//           lineStyle: {
//             type: "dashed", // 线型为虚线
//             color: "#97b3cb",
//           },
//         },
//         axisLine: {
//           lineStyle: {
//             color: "#fff",
//             width: 1,
//           },
//         },
//       },
//     ],
//     series: [
//       {
//         name: "Direct",
//         type: "bar",
//         barWidth: "25%",
//         data: datas.y,
//         itemStyle: {
//           color: "#3399fe",
//         },
//         label: {
//           show: false, // 开启标签显示
//           position: "top", // 标签的位置，可以根据需要调整
//           textStyle: {
//             color: "#45f9d6",
//           },
//         },
//       },
//     ],
//   }
//   return options
// };

export const alartTopCenterOptions = {
  // backgroundColor: "#0f375f",
  grid: {
    top: "25%",
    bottom: "10%", //也可设置left和right设置距离来控制图表的大小
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
      label: {
        show: true,
      },
    },
  },
  legend: {
    data: ["告警数量"],
    top: "15%",
    textStyle: {
      color: "#ffffff",
    },
  },
  xAxis: {
    data: [],
    axisLine: {
      show: true, //隐藏X轴轴线
      lineStyle: {
        color: "#01FCE3",
      },
    },
    axisTick: {
      show: true, //隐藏X轴刻度
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: "#ebf8ac", //X轴文字颜色
      },
    },
  },
  yAxis: [
    {
      type: "value",
      name: "台",
      nameTextStyle: {
        color: "#ebf8ac",
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#FFFFFF",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#ebf8ac",
        },
      },
    },
  ],
  series: [
    {
      name: "告警数量",
      type: "bar",
      barWidth: 15,
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#00FFE3",
            },
            {
              offset: 1,
              color: "#4693EC",
            },
          ]),
        },
      },
      data: [],
    },
  ],
};
