<template>
  <div class="head-class-left">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <!-- <el-form-item label="电站">
                <el-select v-model="formInline.powerStationCode" placeholder="请选择电站">
                    <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName"
                        :value="item.powerStationCode">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="告警名称">
                <el-input v-model="formInline.alarmName" placeholder="请输入告警名称"></el-input>
            </el-form-item> -->
      <el-form-item label="状态">
        <el-select v-model="formInline.status" placeholder="请选择" clearable>
          <el-option label="激活" :value="1"></el-option>
          <el-option label="消除" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="级别">
        <el-select v-model="formInline.level" placeholder="请选择" clearable>
          <el-option label="正常状态" value="0"></el-option>
          <el-option label="紧急状态" value="1"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="设备名称">
                <el-input v-model="formInline.signalName" placeholder="请输入设备名称"></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
                <el-select v-model="formInline.deviceTypeCode" placeholder="请选择">alarmTypeOptions
                    <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.deviceTypeName"
                        :value="item.deviceTypeName">
                        这个地方使用deviceTypeCode，后端返回缺少该字段
                    </el-option>
                </el-select>
            </el-form-item> -->
      <el-form-item label="告警类型">
        <el-select
          v-model="formInline.signalTypeName"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in alarmTypeOptions"
            :key="item.id"
            :label="item.alarmTypeName"
            :value="item.alarmTypeName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产生时间">
        <el-date-picker
          type="daterange"
          v-model="dataTime"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <exportBtn
          :params="{
            url: '/deviceAlarmRecord/exportAlarmRecord',
            buttonText: '导出',
            show: true,
            method: 'post'
          }"
          :data="{
            startTime: dataTime ? dataTime[0] : '',
            endTime: dataTime ? dataTime[1] : ''
          }"
        />
      </el-form-item>
    </el-form>
    <!-- <div class="button-class">
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button type="primary" @click="onSubmit">消除</el-button>
      <el-button type="primary" @click="onSubmit">导出</el-button>
    </div> -->
    <el-table
      :data="tableData"
      border
      style="width: 98%; background: none"
      height="44vh"
      max-height="45vh"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="厂站名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.powerStationName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备名称" align="center">
        <template slot-scope="scope">
          <span @click="toPowerStation" style="color: #80ffff">{{
            scope.row.deviceName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="告警名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.signalName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="告警类型" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.signalTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="级别" align="center">
        <template slot-scope="scope">
          <span style="color: #f59a23">{{
            scope.row.level == 1 ? "紧急" : "正常状态"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="80px">
        <template slot-scope="scope">
          <span style="color: #75bb65" v-if="scope.row.status == 1">激活</span>
          <span style="color: red" v-if="scope.row.status == 0">消缺</span>
        </template>
      </el-table-column>
      <el-table-column label="修复建议" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.dealMessage }}</span>
        </template>
      </el-table-column>

      <el-table-column label="产生时间" width="120px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createtime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="恢复时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.status == 1 ? "" : scope.row.updatetime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否处理" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.handleState == 1
              ? "未处理"
              : scope.row.handleState == 2
              ? "已处理"
              : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100px">
        <template slot-scope="scope">
          <!-- <el-button type="text">修复建议</el-button> -->
          <el-button
            v-if="scope.row.handleState == 1"
            type="text"
            @click="handle(scope.row)"
            >处理</el-button
          >
          <el-button type="text" @click="createWorkOrderApply(scope.row)"
            >转工单</el-button
          >
          <!-- <el-button type="text" @click="play()"
            >关联告警</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[2, 5, 10, 15]"
      :page-size="size"
      class="pagination-style"
      style="margin-right: 20px"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <div v-if="detailVisible === true">
      <AlartEquipmentDialog :deviceSn="deviceSn" @closeClick="closeClick" />
    </div>
    <el-dialog title="工单详情" :visible.sync="dialogVisible" width="50%">
      <el-form
        label-width="120px"
        :model="itemForm"
        class="form-class"
        ref="form"
      >
        <el-form-item label="工单类型：">
          <div class="work-class">
            <!-- :class="itemForm.workOrderType == item.value ? 'select-class' : 'unSelect-class'" -->
            <div
              class="'select-class"
              v-for="(item, index) in workClassList"
              :key="index"
            >
              <div>{{ item.name }}</div>
            </div>
          </div>
        </el-form-item>
        <div class="line-class">
          <el-form-item label="计划开始时间：" prop="planStartTime">
            <el-date-picker v-model="itemForm.planStartTime" type="date">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="计划结束时间：" prop="planEndTime">
            <el-date-picker v-model="itemForm.planEndTime" type="date">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工单级别：" prop="orderLevel">
            <el-select
              v-model="itemForm.orderLevel"
              placeholder="请选择工单级别"
            >
              <el-option label="级别1" :value="0"></el-option>
              <el-option label="级别2" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="工单内容：" prop="workOrderContent">
          <el-input v-model="itemForm.workOrderContent"> </el-input>
        </el-form-item>
        <el-form-item label="修复建议：" prop="proposal">
          <el-input v-model="itemForm.proposal"> </el-input>
        </el-form-item>
        <div class="line-class">
          <el-form-item label="电站名称" prop="powerStationCode">
            <el-select
              v-model="itemForm.powerStationCode"
              placeholder="请选择电站"
            >
              <el-option
                v-for="item in powerStationList"
                :key="item.id"
                :label="item.powerStationName"
                :value="item.powerStationCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="工单流程名称" prop="workOrderNo">
            <el-select
              v-model="itemForm.workOrderNo"
              placeholder="请选择工单流"
            >
              <el-option
                v-for="item in flowWorkOrderApplyList"
                :key="item.id"
                :label="item.workOrderName"
                :value="item.workOrderNo"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <abnormal-alarm ref="abnormalRef"></abnormal-alarm>
  </div>
</template>

<script>
import { generationAnalysisOptions } from "../options/generationAnalysisOptions";
import * as echarts from "echarts";
import AlartEquipmentDialog from "../dialog/alartEquipmentDialog.vue";
import { formatDate } from "@/utils/formatDate";
import exportBtn from "@/components/exportBtnPost.vue";
import Speech from "speak-tts";
import AbnormalAlarm from "../dialog/abnormalAlarm";
import Post from "../../auth/post/Post.vue";

export default {
  components: {
    AlartEquipmentDialog,
    exportBtn,
    AbnormalAlarm
  },
  created() {
    this.createCode = JSON.parse(localStorage.getItem("userInfo")).userCode;
    this.createName = JSON.parse(localStorage.getItem("userInfo")).name;
  },
  mounted() {
    // this.getPlay();
    this.getTableList();
    this.getOptionsList();
    this.SpeechInit();
    // setInterval(() => {
    //   this.getPlay();
    // }, 1000 * 60 * 5);
    this.getSwitchObj();
    this.$refs.abnormalRef.init();
  },
  destroyed() {
    this.speech.cancel();
  },
  data() {
    return {
      formInline: {
        status: 1
      },
      alarmTypeOptions: [], //告警类型
      deviceTypeList: [], //设备类型
      powerStationList: [], //电站
      tableData: [],
      total: 0,
      size: 20,
      currentPage: 1,
      detailVisible: false,
      deviceSn: null, //设备信息
      selectItem: null, // 表格勾选
      dialogVisible: false,
      itemForm: {},
      dataTime: [],
      workClassList: [
        { name: "消缺", value: 1 }
        // { name: '清洗', value: 1 },
        // { name: '检修', value: 2 },
        // { name: '抢修', value: 3 },
        // { name: '其他', value: 4 }
      ],
      isToPlay: true
    };
  },

  methods: {
    // 处理按钮
    async handle(row) {
      this.$confirm("是否对该条进行处理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.handleUpdate(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消处理"
          });
        });
    },
    async handleUpdate(row) {
      let params = {
        id: row.id,
        handleState: 2
      };
      let res = await this.$http.post("/deviceAlarmRecord/update", params);
      if (res.data.code === 200) {
        this.getTableList();
      }
    },
    SpeechInit() {
      this.speech = new Speech();
      this.speech.setLanguage("zh-CN");
      this.speech.init().then(() => {});
    },
    play(text) {
      this.speech
        .speak({
          text: text,
          listeners: {
            //开始播放
            onstart: () => {
              console.log("Start utterance");
            },
            //判断播放是否完毕
            onend: () => {
              console.log("End utterance");
            },
            //恢复播放
            onresume: () => {
              console.log("Resume utterance");
            }
          }
        })
        .then(() => {
          console.log("读取成功");
        });
    },

    async getPlay() {
      const res = await this.$http.post("deviceAlarmRecord/list", {
        condition: {
          status: 1,
          level: 1
        }
      });
      if (res.data.code === 200) {
        const text = res.data.data.data
          .map(item => item.powerStationName + "异常警告")
          .join("、");
        console.log(text);
        this.play(text);
      }
    },
    // 获取是否播报语音
    async getSwitchObj() {
      let res = await this.$http.post("/userInfo/queryOne", {
        userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
      });
      if (res.data.code == 200) {
        let data = res.data.data;
        let isToPlay = data.isBroadcast == 1 ? true : false;
        if (isToPlay) {
          this.getPlay();
          setInterval(() => {
            this.getPlay();
          }, 1000 * 60 * 5);
        }
      }
    },

    //表格勾选
    handleSelectionChange(val) {
      this.selectList = [];
      val.forEach(item => {
        this.selectList.push(item);
      });
    },
    toPowerStation() {
      this.$router.push(`/monitor/monitor/deviceItem`);
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    // 查询
    onSubmit() {
      this.getTableList();
    },
    openDialog(deviceSn) {
      this.deviceSn = deviceSn;
      this.detailVisible = true;
    },
    closeClick() {
      this.detailVisible = false;
    },
    getTableList() {
      // if (this.formInline.createtime) {
      //   this.formInline.createtime = formatDate(
      //     new Date(this.formInline.createtime),
      //     "yyyy-MM-dd"
      //   );
      // }
      let dataTime = {
        startTime: this.dataTime[0],
        endTime: this.dataTime[1]
      }
      let params = {
        condition: {
          createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
          ...this.formInline,
          ...dataTime
          // powerStationCode:localStorage.getItem("powerStationCode"),
        },
        currPage: this.currentPage,
        pageSize: this.size
      };
      this.$http.post("/deviceAlarmRecord/list", params).then(res => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.count;
        }
      });
    },
    getOptionsList() {
      this.$http.post("alarmType/list", {}).then(res => {
        if (res.data.code === 200) {
          this.alarmTypeOptions = res.data.data.data;
        }
      });
      // 电站列表
      this.$http
        .post("powerStation/list", { currPage: 1, pageSize: 10000 })
        .then(res => {
          if (res.data.code == 200) {
            this.powerStationList = res.data.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });

      // 设备类型
      this.$http
        .post("deviceType/queryList", { currPage: 1, pageSize: 10000 })
        .then(res => {
          if (res.data.code == 200) {
            this.deviceTypeList = res.data.data;
            console.log(" this.deviceTypeList", this.deviceTypeList);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    // 转工单
    createWorkOrderApply(item) {
      this.$router.push(
        `/yunwei/yunwei/goWorkOrder?createby=${item.createby}&id=${item.id}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.head-class-left {
  width: 100%;
  border-radius: 5px;
  background-color: #132c45;
  box-sizing: border-box;
  padding: 10px 0 10px 25px;

  .electrcity-chart-class {
    width: 250px;
    height: 170px;
    box-sizing: border-box;

    .fourth-left {
      height: 100%;
      width: 100%;
    }
  }

  .button-class {
    display: flex;
    margin-right: 20px;
    margin-bottom: 5px;
    float: right;

    button {
      margin-left: 10px;
    }
  }
}

.work-class {
  display: flex;

  .select-class {
    width: 105px;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #02a7f0;
    background-color: #fff;
    font-family: "Arial", sans-serif;
    color: #02a7f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 5px;

    .img1 {
      width: 21px;
      height: 15px;
      position: absolute;
      bottom: -1px;
      right: -1px;
      z-index: 1;
    }

    // .img2 {
    //     width: 19.5px;
    //     height: 14px;
    //     position: absolute;
    //     bottom: 0px;
    //     right: 0px;
    //     background: #02a7f0;

    //     img {
    //         width: 16px;
    //         height: 10px;
    //         position: absolute;
    //         bottom: 0px;
    //         right: 0px;
  }
}

/deep/ .el-form-item--small.el-form-item {
  margin-bottom: 8px;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
/deep/ .el-form-item__label {
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
}

/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}

/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;

  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

/deep/ .el-range-editor--small .el-range-input {
  background-color: #142c45;
  color: #fff;
}
::v-deep .el-checkbox {
  margin-right: 0;
}
</style>
