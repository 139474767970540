<template>
    <div class="correlation-alart">
        <el-table
        :data="tableData"
        border
        :height="tableHeight"
        highlight-current-row
        style="width: 100%">
            <el-table-column
                label="厂站名称"
                align="center"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.powerStationName }}</span>
                </template>
            </el-table-column>
            <el-table-column
            label="设备类型"
            align="center"
            >
                <template slot-scope="scope">
                    <span>{{scope.row.deviceTypeName}}</span>
                </template>
            </el-table-column>  
            <el-table-column
            label="设备名称"
            align="center"
            >
                <template slot-scope="scope">
                    <span>{{scope.row.deviceName}}</span>
                </template>
            </el-table-column>
            <el-table-column
            label="告警类型"
            align="center"
            >
                <template slot-scope="scope">
                    <span style="#02a7f0">{{scope.row.alarmTypeName}}</span>
                </template>
            </el-table-column>         
            <el-table-column
            label="告警名称"
            align="center"
            >
                <template slot-scope="scope">
                    <span>{{scope.row.alarmName}}</span>
                </template>
            </el-table-column>       
            <el-table-column label="级别" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.alarmLevel==1">提示告警</span>
                    <span v-if="scope.row.alarmLevel==2">次要告警</span>
                    <span v-if="scope.row.alarmLevel==3">重要告警</span>
                    <span v-if="scope.row.alarmLevel==4">紧急告警</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.status===1?'激活':'消除' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="产生时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.recordTime }}</span>
                </template>
            </el-table-column>
            <el-table-column
            label="回复时间"
            align="center"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.restoreTime }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column
            label="修复建议"
            align="center"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.principal }}</span>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[2, 5, 10, 15]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>
<script>
export default{
    props: {
        deviceSn: {
            typeof: String,
            default: null
        }
    },
    mounted(){
        this.getAlarmList()
    },
    data(){
        return {
            tableData: [],
            total: 0,
            size: 10,
            currentPage: 1,
            tableHeight: window.innerHeight - 390, //表格高度
        }
    },
    methods: {
        handleSizeChange(val){
            this.size = val
            this.getAlarmList()
        },
        handleCurrentChange(val){
            this.currentPage = val
            this.getAlarmList()
        },
        toPowerStation() {
            console.log('dddddddddddddddddd')
        },
        getAlarmList(){
            let params = {
                "condition": {
                    "deviceSn":this.deviceSn
                },
                "currPage": this.currentPage,
                "pageSize": this.size
            }
            this.$http.post('dataStickAlarmRecord/list',params).then(res=>{
                if(res.data.code===200){
                    this.tableData = res.data.data.data
                    this.total = res.data.data.count
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.correlation-alart{
    margin-top: 20px;
    /deep/ .el-table--border, .el-table--group {
        border: 1px solid rgba(151, 179, 203, 0.55);
    }
    /deep/ .el-table--border th{
        background-color: #d7d7d7;

     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #000000;
    }
    /deep/ .el-table td{
        background-color: #ffffff;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #555555;
    }
  /deep/ .el-table__body-wrapper{
    background: #ffffff;
  }
    ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #fff !important;
}
  /deep/ .el-pagination{
    display: flex;
    justify-content: flex-end;
  }
    /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #333;
    background-color: #fff;
    cursor: default;
    }
    /deep/ .el-pager li{
        background: none;
        color: #333;
    }
    /deep/ .el-icon{
        color: #333;
    }
    /deep/ .el-pagination .btn-next{
        background: none;
    }
  /deep/ .el-pagination__total{
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
 /deep/ .el-pagination__jump{  
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
/deep/ .el-input__inner{
  color: #333;
}
}
</style>
