import { render, staticRenderFns } from "./Post.vue?vue&type=template&id=1706f740&scoped=true"
import script from "./Post.vue?vue&type=script&lang=js"
export * from "./Post.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1706f740",
  null
  
)

component.options.__file = "Post.vue"
export default component.exports