<template>
    <div class="correlation-alart">
        <el-form :inline="true" :model="formInline" label-width="80px">
            <el-form-item label="指标">
                <el-input v-model="formInline.number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="时间">
                <el-time-picker placeholder="请选择日期" v-model="formInline.date" style="width: 100%;">
                </el-time-picker>
            </el-form-item>
            <el-form-item label="时间粒度">
                <el-select v-model="formInline.value" placeholder="请选择">
                    <el-option
                    v-for="item in formInline.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
        <div> 
            <div id="alartInformationChart" style="width: 100%;height:250px"></div>
        </div>
        <el-table
        :data="tableData"
        border
        highlight-current-row
        style="width: 100%;max-height:200px;overflow: auto;">
            <el-table-column
                label="日期"
                align="center"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.pollingType }}</span>
                </template>
            </el-table-column>  
            <el-table-column
            label="信号点集合"
            align="center"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.principal }}</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[2, 5, 10, 15]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>
<script>
import {alartInformationOptions} from '../options/alartInformationOptions'
import * as echarts from "echarts";

export default{
    data(){
        return {
            formInline: {},
            options: [],
            tableData: [],
            total: 0,
            size: 10,
            currentPage: 1,
        }
    },
    mounted(){
        this.init()
    },
    beforeDestory() {
        this.alartInformationOptions.dispose();
    },
    methods: {
        init() {
            setTimeout(() => {
                this.alartInformationOptions = echarts.init(
                    document.getElementById("alartInformationChart")
                );
                let option = alartInformationOptions;
                this.alartInformationOptions.setOption(option);
            }, 200);
            window.onresize = function () {
                if (this.alartInformationOptions) {
                this.alartInformationOptions.resize(); // 调用 ECharts 实例的 resize 方法
                }
            }.bind(this);
        },
        onSubmit(){},
        handleSizeChange(val){
            this.size = val
            // this.getListData()
        },
        handleCurrentChange(){
            this.currentPage = val
            // this.getListData()
        },
    }
}
</script>
<style scoped lang="scss">
.correlation-alart{
    margin-top: 20px;
    /deep/ .el-table--border, .el-table--group {
        border: 1px solid rgba(151, 179, 203, 0.55);
    }
    /deep/ .el-table--border th{
        background-color: #d7d7d7;

     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #000000;
    }
    /deep/ .el-table td{
        background-color: #ffffff;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #555555;
    }
  /deep/ .el-table__body-wrapper{
    background: #ffffff;
  }
    ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #fff !important;
}
  /deep/ .el-pagination{
    display: flex;
    justify-content: flex-end;
  }
    /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #333;
    background-color: #fff;
    cursor: default;
    }
    /deep/ .el-pager li{
        background: none;
        color: #333;
    }
    /deep/ .el-icon{
        color: #333;
    }
    /deep/ .el-pagination .btn-next{
        background: none;
    }
  /deep/ .el-pagination__total{
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
 /deep/ .el-pagination__jump{  
    color: #333;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
/deep/ .el-input__inner{
  color: #333;
}
    /deep/  .el-form-item__label{
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 15px;
    }
    /deep/ .el-input__inner{
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 13px;
    }
}
</style>
