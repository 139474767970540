<template>
  <div class="head-class-left">
    <div class="title-class">告警统计</div>
    <div class="fault-chart-class">
      <div
        ref="alartStatisticsChart"
        id="alartStatisticsChart"
        class="fourth-left"
      ></div>
    </div>
  </div>
</template>

<script>
import { alartStatisticsOptions } from "../options/alartStatisticsOptions";
import * as echarts from "echarts";

export default {
  mounted() {
    this.init();
    // this.getCountByAlarmType()
  },
  data() {
    return {
      alarmTypeList: [],
      itemData: {},
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.alartStatisticsChart = echarts.init(
          document.getElementById("alartStatisticsChart")
        );
        this.getCount();
      }, 200);
    },

    async getCount() {
      const res = await this.$http.post("deviceAlarmRecord/selectCountByCode", {
        // powerStationCode: localStorage.getItem("powerStationCode"),
      });
      if (res.data.code === 200) {
        const data = res.data.data.map((item) => {
          return { value: item.count, name: item.signalTypeName };
        });

        let option = alartStatisticsOptions(data);
        this.alartStatisticsChart.setOption(option);

      } else {
        this.$message.error(res.data.message);
      }
    },

    // 获取告警统计
    // async getCountByAlarmType() {
    //     // 这里的接口报错
    //     let res = await this.$http.post("alarmManage/queryCountByAlarmType", { createCode: JSON.parse(localStorage.getItem('userInfo')).userCode });
    //     // console.log("获取告警统计", res);
    //     if (res.data.code === 200) {
    //         res.data.data.forEach(item => {
    //             Object.keys(item).forEach(element => {
    //                 if (element.indexOf('Count') > 0) {
    //                     this.itemData = { value: item[element] }
    //                 } else if (element.indexOf('Name') > 0) {
    //                     this.itemData = { name: item[element] }
    //                 }
    //             })
    //             this.alarmTypeList.push(this.itemData)
    //         })
    //         this.itemData = {}
    //     }
    //     let option = alartStatisticsOptions(this.alarmTypeList);
    //     this.alartStatisticsChart.setOption(option);
    // },
  },
};
</script>

<style lang="scss" scoped>
.head-class-left {
  width: 33%;
  height: auto;
  border-radius: 5px;
  background-color: #132c45;
  box-sizing: border-box;
  padding: 23px 0 23px 25px;

  .title-class {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #ffffff;
    font-size: 14px;
  }

  .left-content {
    display: flex;
    align-items: center;
    padding: 25px;

    .lh-item {
      width: 120px;
      padding: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lh-number-class {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #33e9bd;
        font-size: 16px;
      }

      .lh-text-class {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #f2f2f2;
        font-size: 13px;
      }
    }
  }
}

.fault-chart-class {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .fourth-left {
    height: 100%;
    width: 100%;
  }
}
</style>
