<template>
    <div>
        <el-dialog
        title="设备信息"
        :visible.sync="addDialogVisible"
        width="80%"
        @close="closeDialog"
        >
            <div class="head-class">
                <div
                v-for="(item,index) in titleList" 
                :class="item.value === currentNavigation?'item-head-select':'item-head-unSelect'"
                :key="index"
                @click="currentNavigation = item.value"
                >
                    <div class="title-class">{{item.name}}</div>
                    <img v-if="item.value === currentNavigation" src="../../../assets/images/yunwei/home-background.svg" class="background-img" alt="">
                </div>
            </div>
            <div v-if="currentNavigation === 0" class="no-data-class">
                <img src="../../../assets/images/yunwei/noData.png" alt="">
                <div>暂无数据</div>
            </div>
            <div class="content-class" v-if="currentNavigation === 1">
                <div class="item-title">
                    <div class="title-left"></div>
                    <div>工单信息</div>
                </div>
                <div style="display: flex; flex-wrap:wrap">
                    <div class="form-class" v-for="(item,index) in formList">
                        <div class="form-item-class">
                            <div class="name-class">{{item.name}}</div>
                            <div class="value-class">{{item.value || '--'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <AlartInformation :deviceSn="deviceSn" v-if="currentNavigation === 2"/>
            <HistoryInformation v-if="currentNavigation === 3"/>
        </el-dialog>
    </div>
</template>
<script>
import AlartInformation from '../components/alartInformation.vue'
import HistoryInformation from '../components/historyInformation.vue'
export default {
    components: {
        AlartInformation,
        HistoryInformation
    },
    props:{
        deviceSn:{
            typeof: String,
            default: null
        }
    },
    mounted(){
        this.getDevice()
    },
  data() {
    return {
        addDialogVisible: true,
        titleList: [{name: '实时信息',value: 0},
        {name: '设备信息',value: 1},
        {name: '告警信息',value: 2},
        {name: '历史信息',value: 3}],
        currentNavigation: 0, // 顶部导航
        formList: [
            {name: '设备名称：', value: '',key: 'deviceName'},
            {name: '厂商名称：', value: '',key: 'factory'},
            {name: '设备类型：', value: '',key: 'deviceTypeName'},
            {name: 'IP地址：', value: '',key: 'ip'},
            {name: 'SN号：', value: '',key: 'deviceSn'},
            {name: '型号：', value: '',key: 'modelName'},
            {name: '设备地址：', value: '',key: 'deviceAddress'},
            {name: '经度：', value: '',key: 'longitude'},
            {name: '纬度：', value: '',key: 'latitude'},
            {name: '厂站编号：', value: '',key: 'powerStationCode'},
            {name: '软件版本号：', value: '',key: 'versionCode'},
            {name: '分组信息：', value: '',key: ''},
            {name: '投产日期：', value: '',key: 'operateTime'},
            {name: '保修日期：', value: '',key: 'maintenanceTime'},
            {name: '备注：', value: '',key: 'remark'},
        ]
    }
  },
  methods:{
    closeDialog(){
        this.addDialogVisible = false
        this.$emit('closeClick')
    },
    getDevice(){
        this.$http.post('powerStationDevice/queryOne',{deviceSn: this.deviceSn}).then(res=>{
            if(res.data.code===200){
                Object.keys(res.data.data).forEach(item=>{
                    this.formList.forEach((element,index)=>{
                        if(item === element.key){
                            this.formList[index].value = res.data.data[item]
                        }
                    })
                })
            }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog{
    height: 75%;
}
/deep/ .el-dialog__body{
    padding: 3px 20px;
}
.head-class{
    display: flex;
    border-bottom: 1px solid #009fde;
    .item-head-select{
        margin-right: 10px;
        display: flex;
        height: 38px;
        width: 100%;
        position: relative;
        .title-class{
            width: 95px;
            height: 100%;
            line-height: 40px;
            padding-left: 5px; 
            font-family: "Arial", sans-serif;
            color: #ffffff;
            font-size: 15px;
            background: #00b2e6;
        }
        .background-img{
            height: 100%;
            position: absolute;
            left: 61px;
        }
    }
    .item-head-unSelect{
        display: flex;
        align-items: center;
        height: 38px;
        width: 100%;
        font-family: "Arial", sans-serif;
        color: #aaaaaa;
        font-size: 15px;
    }
}
.no-data-class{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    img{
        width: 50px;
        height: 50px;
    }
}
.content-class{
    .item-title{
        display: flex;
        align-items: center;
        font-family: "Arial", sans-serif;
        color: #333333;
        font-size: 17px;
        margin: 15px 5px;
        .title-left{
            height: 20px;
            width: 5px;
            border-radius: 5px;
            border: 1px solid #02a7f0;
            background-color: #02a7f0;
            margin-right: 5px;
        }
    }
    .form-class{
        display: flex;
        width: 33%;
        .form-item-class{
            display: flex;
            margin-bottom: 15px;
            .name-class{
                font-family: "Arial", sans-serif;
                color: #555555;
                font-size: 15px;
                width: 100px;
                text-align: right;
            }
            .value-class{
                font-family: "Arial", sans-serif;
                color: #000000;
                font-size: 15px;
            }
        }
    }
}
</style>
