<template>
  <div class="head-class-left">
    <div class="title-class">月电量统计</div>
    <div class="fault-chart-class">
      <div
        ref="alartTopCenterChart"
        id="alartTopCenterChart"
        class="fourth-left"
      ></div>
    </div>
  </div>
</template>

<script>
import { alartTopCenterOptions } from "../options/alartTopCenterOptions";
import * as echarts from "echarts";

export default {
  mounted() {
    this.init();
  },
  data() {
    return {
      powerStationList: [],
      echartData: {
        x: [],
        y: [],
      },
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.alartTopCenterChart = echarts.init(
          document.getElementById("alartTopCenterChart")
        );
        this.getCountByDate();
      }, 200);
    },
    // 获取月电量统计
    async getCountByDate() {
      const data = {
        dateFormat: "%Y-%m",
        deviceSn: "",
        // powerStationCode: localStorage.getItem("powerStationCode"),//"3",
        summaryBeDate: "",
        summaryDate: "",
        summaryEddDate: "",
        userCode: "",
      };
      const res = await this.$http.post(
        "deviceAlarmRecord/selectCountByDate",
        data
      );
      if (res.data.code === 200) {
        alartTopCenterOptions.xAxis.data = res.data.data.map(
          (item) => item.date
        );
        alartTopCenterOptions.series[0].data = res.data.data.map(
          (item) => item.count
        );

        this.alartTopCenterChart.setOption(alartTopCenterOptions);
      }
      console.log(res, 56);
    },
  },
};
</script>

<style lang="scss" scoped>
.head-class-left {
  width: 33%;
  height: auto;
  border-radius: 5px;
  background-color: #132c45;
  box-sizing: border-box;
  padding: 23px 0 23px 25px;

  .title-class {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #ffffff;
    font-size: 14px;
  }

  .left-content {
    display: flex;
    align-items: center;
    padding: 25px;

    .lh-item {
      width: 120px;
      padding: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lh-number-class {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #33e9bd;
        font-size: 16px;
      }

      .lh-text-class {
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #f2f2f2;
        font-size: 13px;
      }
    }
  }
}

.fault-chart-class {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .fourth-left {
    height: 100%;
    width: 100%;
  }
}
</style>
