import { render, staticRenderFns } from "./alartBottom.vue?vue&type=template&id=c64283ee&scoped=true"
import script from "./alartBottom.vue?vue&type=script&lang=js"
export * from "./alartBottom.vue?vue&type=script&lang=js"
import style0 from "./alartBottom.vue?vue&type=style&index=0&id=c64283ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c64283ee",
  null
  
)

component.options.__file = "alartBottom.vue"
export default component.exports