export const alartStatisticsOptions = (datas) => {
  let count = 0;
  datas.forEach((item) => {
    console.log("item.value", datas, item);
    count += item.value;
  });
  let options = {
    tooltip: {
      trigger: "item", // 标题
      formatter: "{b}: {d}%", // 数据的显示方式
    },

    legend: {
      textStyle: {
        //图例文字的样式
        color: "#97b3cb",
        fontSize: 12,
      },
      orient: "vertical",
      top: "center",
      // bottom: "25%",
      left: "55%",
      formatter: (name) => {
        let currentData = null;
        datas.forEach((item) => {
          if (item.name === name) {
            currentData = item.value;
          }
        });
        return name + currentData;
      },
    },

    title: {
      text: count,
      subtext: "告警总数",
      textStyle: {
        fontSize: 15,
        color: "#2acfa9",
        fontWeight: 600,
      },
      subtextStyle: {
        fontSize: 13,
        color: "#f2f2f2",
        fontWeight: 400,
      },
      textAlign: "center", //图例文字居中显示
      x: "24%", //距离左边的距离
      y: "43%", //距离上边的距离
    },
    color: [
      "#75bb65",
      "#c46b6d",
      "#75bb65",
      "#c5986f",
      "#c46b6d",
      "#58cfd7",
      "#849ac9",
    ],
    series: [
      {
        type: "pie",
        radius: ["55%", "75%"],
        center: ["25%", "50%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        data: datas,
      },
    ],
  };
  return options;
};
