<template>
  <div class="export-btn">
    <el-button v-if="params.show" type="primary" @click="downloadExcel">{{
      params.buttonText || "导出"
    }}</el-button>
  </div>
</template>
<script>
import { type } from "jquery";

export default {
  name: "ExportBtn",
  props: {
    //expParams
    data: {
      type: Object,
      default: function() {}
    },
    params: {
      type: Object,
      default: function() {
        return {
          url: "",
          buttonText: "",
          method: "",
          fileName: "",
          show: false
        };
      }
    }
  },
  methods: {
    // 下载模版
    async downloadExcel() {
      const _url = this.params.url; //|| 'powerStationDevice/export'
      if (!_url) {
        this.$message.warning("导出功能维护中...");
        return;
      }
      this.$message.success("开始下载...请稍等");
      const res = await this.$http({
        method: this.params.method || "get",
        url: _url,
        data: this.data,
        responseType: "blob" // 设置响应类型为blob，用于处理文件流}, {}, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //   Accept: "application/json", // 告诉服务器您希望接收JSON响应
        // },
      });
      const blob = new Blob([res.data]);
      // 创建一个临时的URL
      const url = window.URL.createObjectURL(blob);
      // 创建一个<a>标签用于下载
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // 设置下载的文件名
      a.download = this.params.fileName || "template.xlsx";
      document.body.appendChild(a);
      a.click();
      // 释放URL对象
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }
};
</script>
<style lang="scss" scoped>
.export-btn {
  display: inline-block;
  margin: 0 14px;
}
</style>
