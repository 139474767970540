import { render, staticRenderFns } from "./alartInformation.vue?vue&type=template&id=29f45362&scoped=true"
import script from "./alartInformation.vue?vue&type=script&lang=js"
export * from "./alartInformation.vue?vue&type=script&lang=js"
import style0 from "./alartInformation.vue?vue&type=style&index=0&id=29f45362&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29f45362",
  null
  
)

component.options.__file = "alartInformation.vue"
export default component.exports